import React, { useEffect, useState } from 'react';
import {
    CircularProgress,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { Recipient } from '../../@types';
import { useProvideSnackBar, useRecipients } from '../../hooks';
import { useGetEmployeesQuery } from '../../graphql';

const initialRecipients: Recipient[] = [
    {
        name: 'Zámbó Dávid',
        phoneNumber: '06702855484',
    },
];
const PhoneBook = () => {
    const { showSuccess } = useProvideSnackBar();
    const { data, loading, error } = useGetEmployeesQuery();
    const { recipients, addRecipient } = useRecipients();

    const [term, setTerm] = useState<string>('');
    const [phoneBookMembers, setPhoneBookMembers] = useState<Recipient[]>([]);

    useEffect(() => {
        if (!data?.getAllEmployees?.length) {
            return;
        }

        setPhoneBookMembers(
            [
                ...initialRecipients,
                ...data.getAllEmployees.map(e => {
                    return {
                        name: e.legalName,
                        phoneNumber: e.phoneNumber,
                    };
                }),
            ].sort((a, b) => ((a.name || '') < (b.name || '') ? -1 : 1))
        );
    }, [data]);

    useEffect(() => {
        if (!error) return;
        console.error({ error });
    }, [error]);

    return (
        <Stack width={'100%'} height={'84vh'}>
            <TextField label={'Keresés'} variant={'outlined'} focused fullWidth value={term} onChange={e => setTerm(e.target.value)} />

            {phoneBookMembers.length && !error ? (
                <TableContainer component={Stack} sx={{ height: 'inherit', width: '100%', display: 'table-fix' }}>
                    <Table sx={{}}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ px: 0.25, py: 1 }}>
                                    <strong>Név</strong>
                                </TableCell>
                                <TableCell align='right' sx={{ px: 0.25, py: 1 }}>
                                    <strong>Telefonszám</strong>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {phoneBookMembers
                                .filter(m => m.name?.toLowerCase().includes(term.toLowerCase()))
                                .filter(r => !recipients.some(rec => rec.phoneNumber === r.phoneNumber))
                                .map(r => (
                                    <TableRow
                                        key={r.name + '-' + r.phoneNumber}
                                        onClick={() => {
                                            showSuccess(`${r.name} hozzáadva a címzettekhez`);
                                            addRecipient(r);
                                        }}>
                                        <TableCell sx={{ px: 0.25, py: 1 }} scope='row'>
                                            {r.name}
                                        </TableCell>
                                        <TableCell align={'right'} sx={{ px: 0.25, py: 1 }}>
                                            {r.phoneNumber}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    {!loading ? <Typography paragraph>Nincs elérhető címjegyzék</Typography> : <CircularProgress />}
                </Stack>
            )}
        </Stack>
    );
};
export { PhoneBook };
