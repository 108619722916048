import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string | number; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
};

export type Card = {
    __typename?: 'Card';
    number?: Maybe<Scalars['String']['output']>;
    type: Scalars['String']['output'];
    validTo?: Maybe<Scalars['String']['output']>;
};

export enum CardType {
    ADDRESS_CARD = 'ADDRESS_CARD',
    DRIVER_QUALIFICATION_LICENCE_CARD = 'DRIVER_QUALIFICATION_LICENCE_CARD',
    DRIVING_LICENCE_CARD = 'DRIVING_LICENCE_CARD',
    ID_CARD = 'ID_CARD',
    TACHOGRAPH_CARD = 'TACHOGRAPH_CARD',
}

export type CompanyDoctor = {
    __typename?: 'CompanyDoctor';
    address: Scalars['String']['output'];
    email: Scalars['String']['output'];
    name: Scalars['String']['output'];
    phone: Scalars['String']['output'];
};

export enum Conductor {
    NORTH = 'NORTH',
    SOUTH = 'SOUTH',
}

export type Contract = {
    __typename?: 'Contract';
    dailyWorkHours: Scalars['Int']['output'];
    employmentType: EmploymentType;
    from: Scalars['String']['output'];
    jobTitle: JobTitle;
    signedAt: Scalars['String']['output'];
    to?: Maybe<Scalars['String']['output']>;
    uuid: Scalars['String']['output'];
    wage: Scalars['Int']['output'];
    wageType: WageType;
};

export type DriverAttribute = {
    __typename?: 'DriverAttribute';
    classification: DriverClassification;
    ibutton?: Maybe<Scalars['String']['output']>;
    siteAddress?: Maybe<Scalars['String']['output']>;
};

export enum DriverClassification {
    BUS_FLEX = 'BUS_FLEX',
    BUS_LOCAL = 'BUS_LOCAL',
    INTERNATIONAL = 'INTERNATIONAL',
    LARGE_BUS_FLEX = 'LARGE_BUS_FLEX',
    LARGE_BUS_LOCAL = 'LARGE_BUS_LOCAL',
}

export type Employee = {
    __typename?: 'Employee';
    bankAccount: Scalars['String']['output'];
    birthName?: Maybe<Scalars['String']['output']>;
    cards: Array<Maybe<Card>>;
    companyDoctor?: Maybe<CompanyDoctor>;
    conductors?: Maybe<Array<Maybe<Conductor>>>;
    contracts: Array<Maybe<Contract>>;
    dateOfBirth: Scalars['String']['output'];
    driverAttribute?: Maybe<DriverAttribute>;
    email: Scalars['String']['output'];
    employmentType: EmploymentType;
    firstName: Scalars['String']['output'];
    fullName: Scalars['String']['output'];
    hasAnnuity: Scalars['Boolean']['output'];
    healthCareNumber: Scalars['String']['output'];
    id: Scalars['Int']['output'];
    lastName: Scalars['String']['output'];
    legalName: Scalars['String']['output'];
    medicalFitnessValidTo?: Maybe<Scalars['String']['output']>;
    mothersName: Scalars['String']['output'];
    nickName?: Maybe<Scalars['String']['output']>;
    permanentAddress: Scalars['String']['output'];
    phoneNumber: Scalars['String']['output'];
    placeOfBirth: Scalars['String']['output'];
    retired: Scalars['Boolean']['output'];
    status: Status;
    taxNumber: Scalars['String']['output'];
    temporaryAddress?: Maybe<Scalars['String']['output']>;
    uuid: Scalars['ID']['output'];
};

export type EmployeeFilter = {
    __typename?: 'EmployeeFilter';
    conductor?: Maybe<Scalars['String']['output']>;
    employmentType?: Maybe<EmploymentType>;
    expiration?: Maybe<Scalars['String']['output']>;
    expiredDocumentType?: Maybe<ExpiredDocumentType>;
    jobTitle?: Maybe<Scalars['String']['output']>;
    page?: Maybe<Scalars['Int']['output']>;
    retired?: Maybe<Scalars['String']['output']>;
    size?: Maybe<Scalars['Int']['output']>;
    status?: Maybe<Status>;
    term?: Maybe<Scalars['String']['output']>;
};

export enum EmploymentType {
    CONTRACTOR = 'CONTRACTOR',
    PERMANENT = 'PERMANENT',
    SIMPLE_EMPLOYMENT = 'SIMPLE_EMPLOYMENT',
}

export enum ExpiredDocumentType {
    ADDRESS_CARD = 'ADDRESS_CARD',
    DRIVER_QUALIFICATION_LICENCE_CARD = 'DRIVER_QUALIFICATION_LICENCE_CARD',
    DRIVING_LICENCE_CARD = 'DRIVING_LICENCE_CARD',
    ID_CARD = 'ID_CARD',
    MEDICAL_FITNESS_VALID_TO = 'MEDICAL_FITNESS_VALID_TO',
    TACHOGRAPH_CARD = 'TACHOGRAPH_CARD',
}

export type JobTitle = {
    __typename?: 'JobTitle';
    description?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
};

export type Query = {
    __typename?: 'Query';
    getAllEmployees?: Maybe<Array<Employee>>;
    getEmployeeByUUID?: Maybe<Employee>;
};

export type QueryGetAllEmployeesArgs = {
    conductor?: InputMaybe<Scalars['String']['input']>;
    employmentType?: InputMaybe<EmploymentType>;
    expiration?: InputMaybe<Scalars['String']['input']>;
    expiredDocumentType?: InputMaybe<ExpiredDocumentType>;
    jobTitle?: InputMaybe<Scalars['String']['input']>;
    retired?: InputMaybe<Scalars['String']['input']>;
    status?: InputMaybe<Status>;
    term?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetEmployeeByUuidArgs = {
    uuid?: InputMaybe<Scalars['ID']['input']>;
};

export enum Status {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum WageType {
    DAILY = 'DAILY',
    HOURLY = 'HOURLY',
    MONTHLY = 'MONTHLY',
}

export type GetEmployeesQueryVariables = Exact<{ [key: string]: never }>;

export type GetEmployeesQuery = {
    __typename?: 'Query';
    getAllEmployees?: Array<{ __typename?: 'Employee'; legalName: string; phoneNumber: string }> | null;
};

export const GetEmployeesDocument = gql`
    query GetEmployees {
        getAllEmployees {
            legalName
            phoneNumber
        }
    }
`;

/**
 * __useGetEmployeesQuery__
 *
 * To run a query within a React component, call `useGetEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
}

export function useGetEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
}

export type GetEmployeesQueryHookResult = ReturnType<typeof useGetEmployeesQuery>;
export type GetEmployeesLazyQueryHookResult = ReturnType<typeof useGetEmployeesLazyQuery>;
export type GetEmployeesQueryResult = Apollo.QueryResult<GetEmployeesQuery, GetEmployeesQueryVariables>;
