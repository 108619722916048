import React, { useEffect, useState } from 'react';
import {
    Box,
    CssBaseline,
    Divider,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Skeleton,
    Toolbar,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../hooks';
import { Greeter, PageHeader } from './styles';
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { CSSObject, styled, Theme } from '@mui/material/styles';

const drawerWidth = 240;
const NAVBAR_API_ENDPOINT = process.env.REACT_APP_NAVBAR_API_ENDPOINT || '';

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: '60px!important',
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

interface NavBarItemType {
    url: string;
    title: string;
    icon: string;
}

interface NavBarResponse {
    navItems: NavBarItemType[];
}

type Props = {
    children: JSX.Element | JSX.Element[];
};

const Layout: React.FC<Props> = ({ children }) => {
    const { user, signOut } = useAuth();
    const [items, setItems] = useState<NavBarItemType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [hasNavBarResponse, setHasNavBarResponse] = useState<boolean>(false);

    const fetchNavItems = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await fetch(NAVBAR_API_ENDPOINT + '/', {
                headers: {
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });

            if (response.status === 401) {
                signOut();
                return;
            }
            const body: NavBarResponse = await response.json();
            body.navItems && setItems(body.navItems);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
            setHasNavBarResponse(true);
        }
    };

    useEffect(() => {
        fetchNavItems();
    }, []);

    return (
        <Box sx={{ m: 0, p: 0, display: 'flex' }}>
            <CssBaseline />
            <AppBar open={open} sx={{ pr: 0 }}>
                <Toolbar sx={{ px: 0, minHeight: '60px!important' }} disableGutters>
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        onClick={() => setOpen(!open)}
                        edge='start'
                        sx={{
                            marginRight: 2,
                            marginLeft: 1.5,
                            color: 'white',
                            ...(open && { display: 'none' }),
                        }}>
                        <MenuIcon />
                    </IconButton>
                    <PageHeader px={2}>
                        <Link href='/'>
                            <img src='/images/brb_logo_horizontal_white.svg' alt='BuszRent logo' />
                        </Link>
                        {user ? (
                            <Greeter>
                                Üdv, {user?.name}!
                                <IconButton onClick={() => signOut()}>
                                    <LogoutIcon sx={{ fill: 'white' }} />
                                </IconButton>
                            </Greeter>
                        ) : null}
                    </PageHeader>
                </Toolbar>
            </AppBar>
            <Drawer variant='permanent' open={open}>
                <DrawerHeader>
                    <IconButton onClick={() => setOpen(!open)}>{<ChevronLeftIcon />}</IconButton>
                </DrawerHeader>
                <Divider />
                {user && (
                    <List>
                        {hasNavBarResponse
                            ? items
                                ? items.map(item => {
                                      const href =
                                          item.url + (item.url.includes(window.location.hostname) ? '' : `?t=${user.accessToken}&r=${user.refreshToken}`);
                                      return (
                                          <ListItem
                                              key={item.title}
                                              disablePadding
                                              sx={{
                                                  display: 'block',
                                                  '& .MuiListItemButton-root': {
                                                      '&.Mui-selected': {
                                                          backgroundColor: 'rgba(66, 196, 171, 0.2)',
                                                      },
                                                  },
                                              }}
                                              title={item.title}>
                                              <Link href={href} underline={'none'} color={'inherit'}>
                                                  <ListItemButton
                                                      selected={window.location.href.includes(item.url)}
                                                      sx={{
                                                          minHeight: 10,
                                                          justifyContent: open ? 'initial' : 'center',
                                                          px: 2.5,
                                                          py: 0.25,
                                                          '& .MuiListItemButton-root': {
                                                              '& .Mui-selected': {
                                                                  backgroundColor: 'red!important',
                                                              },
                                                          },
                                                      }}>
                                                      <ListItemIcon
                                                          className={'nav-icon-svg'}
                                                          sx={{
                                                              minWidth: 0,
                                                              mr: open ? 1 : 'auto',
                                                              justifyContent: 'center',
                                                          }}
                                                          dangerouslySetInnerHTML={{ __html: item.icon }}
                                                      />
                                                      <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                                                  </ListItemButton>
                                              </Link>
                                          </ListItem>
                                      );
                                  })
                                : null
                            : Object.keys(Array(10).fill(1)).map(item => (
                                  <ListItem key={'skeleteon-nav-' + item} disablePadding sx={{ display: 'block' }}>
                                      <ListItemButton
                                          sx={{
                                              minHeight: 10,
                                              justifyContent: open ? 'initial' : 'center',
                                              px: 2.5,
                                              py: 0.25,
                                          }}>
                                          <ListItemIcon
                                              className={'nav-icon-svg'}
                                              sx={{
                                                  minWidth: 0,
                                                  mr: open ? 1 : 'auto',
                                                  justifyContent: 'center',
                                              }}>
                                              <Skeleton variant='circular' width={'22px'} height={'22px'} />
                                          </ListItemIcon>
                                          <ListItemText
                                              primary={<Skeleton variant='rectangular' width={'169px'} height={'23px'} />}
                                              sx={{ opacity: open ? 1 : 0 }}
                                          />
                                      </ListItemButton>
                                  </ListItem>
                              ))}
                    </List>
                )}
            </Drawer>
            <Box component='main' sx={{ flexGrow: 1, mt: '60px' }}>
                {children}
            </Box>
        </Box>
    );
};

export { Layout };
