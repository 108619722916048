import React, { useState } from 'react';
import { SecuredLayout } from '../Layout/SecuredLayout';
import { Breadcrumbs, Link, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Link as RouterLink } from 'react-router-dom';
import SmsIcon from '@mui/icons-material/Sms';
import { MessageArea } from './MessageArea';
import { PhoneBook } from './PhoneBook';
import { RecipientList } from './RecipientList';
import { AddRecipientField } from './AddRecipientField';
import { SmsHistory } from './SmsHistory';

const SMSSenderPage = () => {
    const [viewId, setViewId] = useState<number>(0);
    return (
        <SecuredLayout>
            <Grid container px={3} pt={1}>
                <Grid xs={12}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link component={RouterLink} underline='hover' sx={{ display: 'flex', alignItems: 'center' }} color='inherit' to='/'>
                            <SmsIcon sx={{ mr: 0.5 }} fontSize='inherit' />
                            SMS küldés
                        </Link>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} p={1}>
                <Grid component={Paper} container sx={{ minHeight: '80vh', width: '100%' }} justifyContent={'center'} p={1}>
                    <Grid xs={12} lg={5}>
                        <Grid container>
                            <Grid sm={12} md p={1} sx={{ height: '100%', maxHeight: 'inherit' }}>
                                <Typography variant={'h5'} px={0.5} pb={2} mb={0}>
                                    SMS
                                </Typography>
                                <AddRecipientField />
                                <RecipientList />
                                <MessageArea onSend={(id: number) => setViewId(id)} />
                            </Grid>
                            <Grid sm={12} md p={1}>
                                <Typography variant={'h5'} px={0.5} pb={2} mb={0}>
                                    Telefonkönyv
                                </Typography>
                                <PhoneBook />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} lg={7} px={1}>
                        <SmsHistory viewId={viewId} />
                    </Grid>
                </Grid>
            </Grid>
        </SecuredLayout>
    );
};

export { SMSSenderPage };
