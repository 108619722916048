import React from 'react';
import { Route, Routes } from 'react-router';
import { NotFoundPage } from './Common';
import { Box, styled } from '@mui/material';
import { ProvideRecipients, useProvideAuth } from '../hooks';
import { LoginPaper } from './Login';
import { LicenseInfo } from '@mui/x-license-pro';
import { ProvideSnackBar } from '../hooks/useSnackBar';
import { SMSSenderPage } from './SMSSenderPage';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY || 'not known';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const StyledApp = styled(Box)`
    background-image: url('/images/brb_logo_vertical_white.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 40%;
    min-height: 100%;
    height: 100%;
`;

const HR_GRAPHQL_ENDPOINT = process.env.REACT_APP_HR_GRAPHQL_ENDPOINT;

const httpLink = createHttpLink({
    uri: HR_GRAPHQL_ENDPOINT,
});

function App() {
    const { user } = useProvideAuth();

    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers,
                authorization: user?.accessToken ? `Bearer ${user.accessToken}` : '',
            },
        };
    });

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
    });

    return (
        <StyledApp>
            <ApolloProvider client={client}>
                <ProvideSnackBar>
                    <ProvideRecipients>
                        <Routes>
                            <Route path='/' element={<SMSSenderPage />} />
                            <Route path='/login' element={<LoginPaper />} />
                            <Route path='*' element={<NotFoundPage />} />
                        </Routes>
                    </ProvideRecipients>
                </ProvideSnackBar>
            </ApolloProvider>
        </StyledApp>
    );
}

export default App;
