import { createContext, useContext, useState } from 'react';
import { Props, Recipient } from '../@types';

type RecipientContextType = {
    recipients: Recipient[];
    addRecipient: (recipient: Recipient) => void;
    removeRecipient: (recipient: Recipient) => void;
    clearRecipients: () => void;
};

const RecipientsContext = createContext<RecipientContextType>({
    recipients: [],
    addRecipient: recipient => console.log(recipient),
    removeRecipient: recipient => console.log(recipient),
    clearRecipients: () => console.log('clear'),
});

const useProvideRecipients = () => {
    const [recipients, setRecipients] = useState<Recipient[]>([]);

    const addRecipient = (recipient: Recipient) => {
        if (recipients.some(r => r.phoneNumber === recipient.phoneNumber)) return;
        setRecipients([...recipients, recipient]);
    };

    const removeRecipient = (recipient: Recipient) => {
        setRecipients(recipients.filter(r => r.phoneNumber !== recipient.phoneNumber));
    };

    const clearRecipients = () => setRecipients([]);

    return {
        recipients,
        addRecipient,
        removeRecipient,
        clearRecipients,
    };
};

const useRecipients = () => {
    return useContext(RecipientsContext);
};

const ProvideRecipients = ({ children }: Props) => {
    const r = useProvideRecipients();

    return <RecipientsContext.Provider value={r}>{children}</RecipientsContext.Provider>;
};

export { useRecipients, useProvideRecipients, ProvideRecipients };
