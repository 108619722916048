import { Chip, Stack, Typography } from '@mui/material';
import React from 'react';
import { useRecipients } from '../../hooks';

const RecipientList = () => {
    const { recipients, removeRecipient } = useRecipients();
    return (
        <Stack sx={{ flexGrow: 1, overflow: 'auto', maxHeight: '11rem' }}>
            <Typography paragraph fontSize={'small'} pt={1} px={1} pb={0} mb={0}>
                Címzettek:
            </Typography>
            <Stack direction='row' sx={{ flexWrap: 'wrap' }}>
                {recipients.length === 0 ? (
                    <Chip label={'Nincs kiválasztva címzett'} sx={{ m: 0.5 }} />
                ) : (
                    recipients.map(rec => (
                        <Chip
                            key={rec.name + rec.phoneNumber}
                            label={rec.name ? `${rec.name}` : rec.phoneNumber}
                            title={rec.phoneNumber}
                            onDelete={() => removeRecipient(rec)}
                            sx={{ m: 0.25 }}
                        />
                    ))
                )}
            </Stack>
        </Stack>
    );
};

export { RecipientList };
