import React, { useState } from 'react';
import { Button, CircularProgress, Stack, styled, TextareaAutosize, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useAuth, useProvideSnackBar, useRecipients } from '../../hooks';

const SMS_ENDPOINT = process.env.REACT_APP_SMS_API_ENDPOINT || '';

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
};

const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 93%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 6px 12px 12px 12px;
    margin-bottom: 0.5rem;
    border-radius: 12px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    resize: none;
    overflow: auto;
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

type MessageAreaProps = {
    onSend: (id: number) => void;
};

const MessageArea = ({ onSend }: MessageAreaProps) => {
    const { user } = useAuth();
    const { recipients, clearRecipients } = useRecipients();
    const { showSuccess, showError } = useProvideSnackBar();
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const calculateMessageCount = (length: number) => {
        if (length <= 153) return 1;
        return Math.ceil((length - 153) / 160) + 1;
    };

    const handleSend = async () => {
        if (loading) return;
        setLoading(true);
        if (recipients.length === 0) {
            showError('Adjon meg címzettet');
            setLoading(false);
            return;
        }

        if (message === '') {
            showError('Hiányzó üzenet');
            setLoading(false);
            return;
        }

        const response = await fetch(SMS_ENDPOINT + '/sms/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.accessToken}`,
            },
            body: JSON.stringify({
                recipients,
                message,
                reference: 'EGYEDI',
                referenceId: new Date().getTime(),
            }),
        });

        setLoading(false);
        onSend(Math.random());
        if (response.ok) {
            setMessage('');
            clearRecipients();
            showSuccess('Az üzenetet elküldtük');
            return;
        }
        showError('Nem sikerült az üzenetet elküldeni');
    };

    return (
        <Stack sx={{ width: '100%', pb: 0.5, my: 0 }}>
            <Typography paragraph fontSize={'small'} px={1} pb={0} mb={0}>
                Üzenet:
            </Typography>
            <StyledTextarea minRows={4} maxRows={4} value={message} onChange={e => setMessage(e.target.value)} />
            <Typography paragraph fontSize={'small'} textAlign={'right'} mb={0.5}>
                {`${message.length}/${calculateMessageCount(message.length)} üzenet`}
            </Typography>
            <Button variant={'contained'} endIcon={loading ? <CircularProgress size={21} color={'inherit'} /> : <SendIcon />} onClick={() => handleSend()}>
                Küldés
            </Button>
        </Stack>
    );
};

export { MessageArea };
