import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Props } from '../@types';
import { AuthContextType, User } from '../@types/auth';
import { decodePayload } from '../utils/decodePayload';
import { LoginPaper } from '../components/Login';

const KEYCLOAK_ENDPOINT = process.env.REACT_APP_OAUTH2_PROVIDER_REFRESH_ENDPOINT;

const AuthContext = createContext<AuthContextType>({
    user: undefined,
    isAuthenticated: false,
    signIn: (authToken: string, refreshToken: string) => console.log('signIn'),
    signOut: () => console.log('signOut'),
    setAuthToken: (token: string) => console.log('setToken'),
    setRefreshToken: (token: string) => console.log('setToken'),
});

const useProvideAuth = () => {
    const [user, setUser] = useState<User>();

    const getAuthToken = () => {
        return localStorage.getItem('auth-token');
    };

    const getRefreshToken = () => {
        return localStorage.getItem('refresh-token');
    };

    const signOut = () => {
        setUser(undefined);
        localStorage.removeItem('auth-token');
        window.location.assign('/');
    };

    const setAuthToken = (token: string) => {
        localStorage.setItem('auth-token', token);
        const updatedUser = Object.assign({}, user);
        updatedUser.accessToken = token;
        setUser(updatedUser);
    };

    const setRefreshToken = (token: string) => {
        localStorage.setItem('refresh-token', token);
        const updatedUser = Object.assign({}, user);
        updatedUser.refreshToken = token;
        setUser(updatedUser);
    };

    const signIn = async (accessToken: string, refreshToken: string) => {
        try {
            const { exp, preferred_username, family_name, given_name, email, realm_access, workplace } = decodePayload(accessToken);

            const resolvedUser = {
                username: preferred_username,
                name: `${family_name} ${given_name}`,
                email,
                roles: realm_access.roles,
                accessToken,
                refreshToken,
                exp,
                workplace,
            };
            setUser(resolvedUser);
        } catch (e) {
            console.error(e);
        }
    };

    const refreshTheToken = async () => {
        if (!KEYCLOAK_ENDPOINT) {
            return;
        }
        const response = await fetch(KEYCLOAK_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                client_id: 'buszrent-crm',
                refresh_token: getRefreshToken() || '',
                grant_type: 'refres_token',
            }),
        });
        const body = await response.json();
        setAuthToken(body.access_token);
        setRefreshToken(body.refresh_token);
        console.log(body);
    };

    useEffect(() => {
        const init = async () => {
            const accessToken = getAuthToken();
            const refreshToken = getRefreshToken() || '';

            if (accessToken) {
                await signIn(accessToken, refreshToken);
            }
        };
        init();
    }, []);

    return {
        user,
        isAuthenticated: user !== undefined && !!user.accessToken,
        signIn,
        signOut,
        setAuthToken,
        setRefreshToken,
    };
};

const useAuth = () => {
    return useContext(AuthContext);
};

const ProvideAuth = ({ children }: Props) => {
    const auth = useProvideAuth();
    const [search, setSearchParams] = useSearchParams();
    const authToken = new URLSearchParams(search).get('t');
    const refreshToken = new URLSearchParams(search).get('r') || '';

    useEffect(() => {
        const initAuth = async () => {
            if (authToken && auth.signIn && typeof auth.signIn === 'function') {
                auth.setAuthToken(authToken);
                auth.setRefreshToken(refreshToken);
                await auth.signIn(authToken, refreshToken);
            }
            search.delete('t');
            search.delete('r');
            setSearchParams(search);
        };
        initAuth();
    }, []);

    if (!auth.user) {
        return <LoginPaper />;
    }

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export { useAuth, useProvideAuth, ProvideAuth };
