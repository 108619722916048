import React, { useEffect, useRef, useState } from 'react';
import { DataGridPro, DataGridProProps, GridColDef } from '@mui/x-data-grid-pro';
import { LinearProgress, Stack, Typography } from '@mui/material';
import { useAuth } from '../../hooks';
import { PageableResponse } from '../../@types/spring-boot';

const SMS_ENDPOINT = process.env.REACT_APP_SMS_API_ENDPOINT || '';

type SmsType = {
    id: string;
    name: string | null;
    phoneNumber: string;
    message: string;
    reference: string;
    referenceId: string;
    created: string;
    sentStatus: string;
};

const SIZE = 40;

const columns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Címzett',
        width: 130,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        align: 'left',
        headerAlign: 'left',
    },
    {
        field: 'phoneNumber',
        headerName: 'Telefonszám',
        width: 120,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        align: 'left',
        headerAlign: 'left',
    },
    {
        field: 'message',
        headerName: 'Üzenet',
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        align: 'left',
        headerAlign: 'left',
        flex: 1,
    },
    {
        field: 'sentStatus',
        headerName: 'Státusz',
        width: 85,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        align: 'left',
        headerAlign: 'left',
    },
    {
        field: 'created',
        headerName: 'Küldés időpontja',
        width: 160,
        sortable: true,
        editable: false,
        filterable: false,
        hideable: false,
        align: 'left',
        headerAlign: 'left',
    },
];

type SmsHistoryProps = {
    viewId: number;
};

const SmsHistory = ({ viewId }: SmsHistoryProps) => {
    const { user } = useAuth();
    const data: SmsType[] = [];
    const mounted = useRef(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadedMessages, setLoadedMessages] = useState<SmsType[]>([]);
    const [totalCount, setTotalCount] = useState<number>(100);
    const [page, setPage] = useState<number>(1);

    const fetchSmsList = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                SMS_ENDPOINT +
                    '/sms/?' +
                    new URLSearchParams({
                        name: '',
                        phoneNumber: '',
                        message: '',
                        page: page.toString(),
                        size: SIZE.toString(),
                    }).toString(),
                {
                    headers: {
                        Authorization: `Bearer ${user?.accessToken}`,
                    },
                }
            );

            const body: PageableResponse<SmsType> = await response.json();
            setTotalCount(body.totalElements);
            if (mounted.current) {
                setLoadedMessages(loadedMessages.concat(body.content));
                setPage(page + 1);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const handleOnRowsScrollEnd: DataGridProProps['onRowsScrollEnd'] = () => {
        if (loadedMessages.length <= totalCount) {
            fetchSmsList();
        }
    };

    useEffect(() => {
        setLoadedMessages([]);
        setPage(1);
    }, [viewId]);

    useEffect(() => {
        return () => {
            mounted.current = true;
        };
    }, []);

    return (
        <Stack>
            <Typography variant={'h5'} py={1}>
                Elküldött üzenetek
            </Typography>
            <div style={{ height: '84vh', width: '100%' }}>
                <DataGridPro
                    {...data}
                    rows={data.concat(loadedMessages)}
                    density={'compact'}
                    columns={columns}
                    loading={loading}
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    onRowsScrollEnd={handleOnRowsScrollEnd}
                    slots={{
                        loadingOverlay: LinearProgress,
                    }}
                    sx={{
                        '.MuiDataGrid-row': {
                            maxHeight: 'unset!important',
                            '.MuiDataGrid-cell': {
                                maxHeight: 'unset!important',
                                alignItems: 'start',
                                padding: '8px 8px',
                            },
                        },
                    }}
                />
            </div>
        </Stack>
    );
};

export { SmsHistory };
