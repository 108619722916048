import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { useRecipients } from '../../hooks';
import { PhoneNumberUtil } from 'google-libphonenumber';

const PHONE_NUMBER_INITIAL_VALUE = '+';
const AddRecipientField = () => {
    const { recipients, addRecipient } = useRecipients();
    const [recipient, setRecipient] = useState<string>(PHONE_NUMBER_INITIAL_VALUE);
    const [recipientError, setRecipientError] = useState<boolean>(false);
    const phoneUtil = PhoneNumberUtil.getInstance();

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        setRecipientError(false);

        if (e.key === 'Enter' || e.key === ' ') {
            if (recipients.find(r => r.phoneNumber === recipient)) {
                return;
            }
            if (!phoneUtil.isValidNumber(phoneUtil.parse(recipient, 'ZZ'))) {
                setRecipientError(true);
                return;
            }
            addRecipient({ phoneNumber: recipient.trim() });
            setRecipient(PHONE_NUMBER_INITIAL_VALUE);
            return;
        }

        if (new RegExp('[0-9]').test(e.key)) {
            setRecipient(recipient + e.key);
            return;
        }

        if (e.key === 'Backspace') {
            setRecipient(recipient.length === 1 ? PHONE_NUMBER_INITIAL_VALUE : recipient.slice(0, recipient.length - 1));
            return;
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
        e.preventDefault();
        const clipboardData = e.clipboardData.getData('text');
        const cleanedText = clipboardData.replace(/\D/g, '');
        setRecipient(`${PHONE_NUMBER_INITIAL_VALUE}${cleanedText}`);
    };

    return (
        <TextField
            fullWidth
            label={'Címzett hozzáadása'}
            helperText={
                recipientError
                    ? 'A telefonszámot nemzetközi formában kell megadni.'
                    : 'SPACE vagy ENTER megnyomása esetén a telefonszámot a címzett listához adja'
            }
            error={recipientError}
            value={recipient}
            onKeyDown={e => handleKeyDown(e)}
            onPaste={e => handlePaste(e)}
        />
    );
};

export { AddRecipientField };
